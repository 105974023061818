'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Container, Box, Typography, Button } from '@mui/material';
import ServerErrorImage from '@/public/static/500.svg';
export default function ErrorPage({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);
  return <Container data-sentry-element="Container" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
      <Box sx={{
      display: 'flex',
      flexFlow: 'column wrap',
      alignItems: 'center',
      minHeight: '90vh',
      height: '100%'
    }} data-sentry-element="Box" data-sentry-source-file="error.tsx">
        <img src={ServerErrorImage} alt="500 Error Image" style={{
        marginTop: 80,
        width: '100%',
        maxWidth: 500,
        height: 'auto',
        display: 'block'
      }} />
        <Typography variant="h6" sx={{
        mt: 2,
        fontWeight: 600
      }} data-sentry-element="Typography" data-sentry-source-file="error.tsx">
          Oops, 500 Server Error
        </Typography>
        <Typography sx={{
        mt: 2
      }} data-sentry-element="Typography" data-sentry-source-file="error.tsx">
          Something went wrong on our end. We&apos;re fixing it faster than you
          can say &apos;automation parts.&apos;
        </Typography>
        <Box sx={{
        mt: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px'
      }} data-sentry-element="Box" data-sentry-source-file="error.tsx">
          <Button variant="outlined" onClick={() => reset()} sx={{
          color: '#2E2C34',
          borderColor: '#98AAEC',
          textTransform: 'none',
          borderRadius: 0,
          py: 1.5,
          px: 6
        }} data-sentry-element="Button" data-sentry-source-file="error.tsx">
            Refresh the Page (sometimes helps)
          </Button>
          <Button href="/" variant="outlined" sx={{
          textDecoration: 'none',
          backgroundColor: '#2E2C34',
          borderColor: '#2E2C34',
          color: 'common.white',
          textTransform: 'none',
          borderRadius: 0,
          py: 1.5,
          px: 6,
          '&:hover, &:hover svg': {
            backgroundColor: '#2E2C34',
            borderColor: '#2E2C34',
            color: 'common.white'
          }
        }} data-sentry-element="Button" data-sentry-source-file="error.tsx">
            Take me to the homepage
          </Button>
        </Box>
      </Box>
    </Container>;
}